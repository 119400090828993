// utils.js

export const getResaDay = (table, date) => {
    const day = new Intl.DateTimeFormat('fr-FR', { weekday: 'long' }).format(date).toUpperCase();
    
    console.log("day chose", day);
  
    // Utilisez un switch pour renvoyer la bonne journée de réservation en fonction du jour de la semaine
    switch (day) {
      case "MONDAY":
        return table.monday;
      case "TUESDAY":
        return table.tuesday;
      case "WEDNESDAY":
        return table.wednesday;
      case "THURSDAY":
        return table.thursday;
      case "FRIDAY":
        return table.friday;
      case "SATURDAY":
        return table.saturday;
      case "SUNDAY":
        return table.sunday;
      default:
        return table.monday;
    }
};  

export const isBetween = (date, datebottom, datetop) => {
    const targethour = date.getHours();
    const targetminutes = date.getMinutes();
    
    console.log("target", targethour);
    
    const bottombornehour = datebottom.getHours();
    const topbornehour = datetop.getHours();
    
    console.log(`bottom ${bottombornehour} --> top ${topbornehour}`);
    
    if (targethour >= bottombornehour && targethour < topbornehour) {
      if (targethour === bottombornehour) {
        const bottomborneminutes = datebottom.getMinutes();
        if (targetminutes >= bottomborneminutes) {
          console.log("target is between");
          return true;
        }
      } else {
        console.log("target is between");
        return true;
      }
    }
    
    console.log("target is not between");
    return false;
  };
  