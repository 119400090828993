import React, { Component, useContext, useEffect, useState } from "react";
import SelectorWithDropdown from "./selectorwithdropdown";
import SelectionnableButton from "./selectionnableButton";
import Fields from "./Fields";
import SectionHorizontal from "./sectionflex";
import SectionBlock from "./sectionblock";
import ReservationContext from "./webService/Reservations";
import CalendarView  from "./calendar";
import TableGrid from "./TableGrid";
import { generateIntermediateSlots } from "./horairesSelectable";
import PhoneNumberInput from './phoneNumberInput'
import TextAreaWithLabel from "./TextAreaWithLabel";
import RestauList from "./RestauList";
import { useNavigate } from 'react-router-dom';
import './loader.css';
import NameField from "./NameField";
import { ActionType } from './ActionType'; // Importation de l'enum

class Reservation extends Component {
  static contextType = ReservationContext;

  state = {
    adults: 1,
    children: 0,
    date: new Date(),
    reservationTime: 90,
    table: 2,
    availableTimes: [],
    phone: "",
    additionalInfo: "",
  };

  componentDidMount() {

    console.log("componentDidMount", this.context.tables.length === 0);

    if (this.context.tables.length === 0) {
      console.log("componentDidMount", this.context.tables);
    }
  }

  handleDateChange = (date) => {
    this.setState({
      date: date
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  
  SelectNumberOfClientsView = () => {
    const { maxCustomers } = useContext(ReservationContext);

    useEffect(() => {
      console.log("Nouvelle valeur de maxCustomers:", maxCustomers);
    }, [maxCustomers]); 
    const options = generateNumberArray(maxCustomers);
    console.log(" valeur de options:", options);

    return(
      <SelectorWithDropdown options={ options } containerName={ "customers" } title={ "Nombre d'adultes :" }/>
    );
  }

  SelectHoraires = () => {
    const { timeSlots, allReservations } = this.context;
    console.log("TIMESLOTS:", timeSlots);
    const allSlots = generateIntermediateSlots(timeSlots);

    console.log("allslots et allResas:", allReservations, allSlots);
    // Call the function and store the result in a variable
    const slotsfiltered = removeAlreadyReservedTables(allReservations, allSlots);
    console.log("SLOTS FILTEREDS:", slotsfiltered);

    slotsfiltered.sort((a, b) => a.time.localeCompare(b.time));

    console.log("final tables to afficher__:", slotsfiltered);
    return(
      <SelectorWithDropdown options={ slotsfiltered } containerName={ "slots" } title={ "Horaires disponibles :" } />
    );
  }

  SelectTable = () => {
    const { tables, selectedTableIds, getCustomersNumber } = useContext(ReservationContext);
    const [filteredTables, setFilteredTables] = useState([]);
  
    console.log("infos--> ", tables, selectedTableIds, getCustomersNumber());
    useEffect(() => {
      if (selectedTableIds !== undefined) {
        // Filtrer les tables par selectedTableIds
        const filtered = tables
          .filter(table => selectedTableIds.includes(table.id))
          .filter(table => table.sits >= getCustomersNumber())
          .sort((a, b) => (a.sits - getCustomersNumber()) - (b.sits - getCustomersNumber())) // Trier par proximité du nombre de places
          .slice(0, 3); // Prendre les 3 premières tables
        setFilteredTables(filtered);
      }
    }, [tables, selectedTableIds, getCustomersNumber()]);
  
    useEffect(() => {
      console.log("filteredTables ", filteredTables);
    }, [filteredTables]);

    return (
      <TableGrid tables={filteredTables} />
    );
  }

  PhonenumberField = () => {
    return(
      <PhoneNumberInput />
    );
  }

  ValidateButton = () => {
    const { validateReservation, canClickReservation, getAddressFromSelection } = this.context;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // State to manage the loading indicator

    const handleReservation = () => {
        setLoading(true); // Enable loader
        validateReservation((timeStartDate, customers, phone, customerName) => {
            navigate('/confirmation', {
              state: {
                name: customerName,
                adresse: getAddressFromSelection(),
                numberOfPeople: customers,
                date: formatDateToFrench(timeStartDate), // Converting the date to string if necessary, or format it as you need
                phone: phone
              }
            });
            setLoading(false); // Disable loader after navigation or on error
        }, () => {
            setLoading(false); // Ensure to disable loader on error
        });
    };

    const isButtonDisabled = !canClickReservation();
    const buttonStyle = {
      opacity: isButtonDisabled ? 0.5 : 1,
      cursor: isButtonDisabled ? 'not-allowed' : 'pointer'
    };

    return(
      <div className="validate-button-container">
        <button className="validate-button Button" onClick={handleReservation} style={buttonStyle} disabled={isButtonDisabled}>{loading ? <div className="loader"></div>: 'Réserver'}</button>
      </div>
    );
  }// 

  TextArea = () => {
    return(
      <TextAreaWithLabel />
    );
  }

DisplayNameField = () => {
  return(
    <NameField />
  );
}

  SelectionRestau = () => {

    const { handleClickRestau } = useContext(ReservationContext);

      // Fonctions pour simuler des actions
  const handleClickMelun = () => handleClickRestau('Melun');
  const handleClickColombes = () => handleClickRestau('Bois-Colombes');
  const handleClickClaye = () => handleClickRestau('Les Clayes-sous-bois');
  const handleClickArcueil = () => handleClickRestau('Arcueil');
  const handleClickPavillon = () => handleClickRestau('Pavillon-sous-bois');
  const handleClickAsnières = () => handleClickRestau('Asnières-sur-Seine');
  const handleClickOpera = () => handleClickRestau('Paris Opéra');
  const handleClickSaintOuen = () => handleClickRestau('Saint Ouen');
  
  // Liste des actions à passer au composant ActionList
 const actions = [
    { id: 1, title: 'Bois-Colombes', onClick: handleClickColombes },
    { id: 2, title: 'Melun', onClick: handleClickMelun },
    { id: 3, title: 'Les Clayes-sous-bois', onClick: handleClickClaye },
//    { id: 4, title: 'Arcueil', onClick: handleClickArcueil },
    { id: 5, title: 'Pavillon-sous-bois', onClick: handleClickPavillon },
    { id: 6, title: 'Asnières-sur-Seine', onClick: handleClickAsnières },
    { id: 7, title: 'Paris Opéra', onClick: handleClickOpera },
    { id: 8, title: 'Saint Ouen', onClick: handleClickSaintOuen },
  ];

    return(
      <RestauList actions={actions}/>
    );
  }

  render() {// Récupérer QuantitySelected depuis le contexte

    const { restauSelected, QuantitySelected } = this.context;
    const shouldShowDetails = restauSelected && restauSelected !== ""; // Ajuste selon la logique exacte de "isnotEmpty"

    return (
      <div>
        <this.SelectionRestau />
       <div className={`ReservationView ${shouldShowDetails ? 'hasContent' : ''}`}>
          {shouldShowDetails && (
            <>
              <SectionHorizontal 
                anotherComponent={this.DisplayNameField}
              />
              <SectionHorizontal 
                anotherComponent={this.SelectNumberOfClientsView}
              />
              <CalendarView/>
              {QuantitySelected && (
                <SectionHorizontal 
                  anotherComponent={this.SelectHoraires}
                />
              )}
              
              <this.SelectTable />
              <this.PhonenumberField />
              <this.TextArea />
              <this.ValidateButton />
            </>
          )}
          </div>
      </div>
      
    );
  }
}

function generateNumberArray(number) {
  // Vérifier si n est un nombre entier positif
  if (!Number.isInteger(number) || number < 1) {
      throw new Error("Le paramètre doit être un nombre entier positif supérieur ou égal à 1.");
  }

  // Créer un tableau vide pour stocker les nombres générés
  const numberArray = [];

  // Boucler de 1 à n et ajouter chaque nombre au tableau
  for (let i = 2; i <= number; i++) {
      numberArray.push(i);
  }

  // Renvoyer le tableau de nombres
  return numberArray;
}

function removeAlreadyReservedTables(reservations, slots) {
  console.log("BEGIN SLOTS__:", slots);
  // console.log("reservations__:", reservations);

  let filteredSlots = slots;
  console.log("REMOVE ALREADY BEGIN", (reservations.length === 0 || slots.length === 0), reservations.length, "for", reservations );

  if (reservations.length === 0 || slots.length === 0) {

    slots.forEach(slot => {
      // Extract hours and minutes from the slot.time string
      const [hours, minutes] = slot.time.split(':').map(str => parseInt(str, 10));
      // Create a new Date object for the slot time on the same day as the reservation
      const { calendardate } = useContext(ReservationContext);
      let slotDateTime = new Date(calendardate);
      slotDateTime.setHours(hours);
      slotDateTime.setMinutes(minutes);
      slotDateTime.setSeconds(0);
      slotDateTime.setMilliseconds(0);

      // delete les slots de la journée déjà passés.
      if (slotDateTime < new Date(new Date().setHours(new Date().getHours() + 1))) {
        let slotToUpdate = filteredSlots.find(s => s.time === slot.time);
        
        slotToUpdate.tables = []
      }
    });
    console.log("RETURN ICI", filteredSlots);
    return filteredSlots;
  }
  console.log("FAIL ICI", reservations, "\rfor",slots);

  reservations.forEach(reservation => {
    // Log to check the content of timeStart

    // If timeStart and timeEnd are not undefined
    if (reservation.timeStart && reservation.timeEnd) {
      // Check if we have the toDate function, implying a Timestamp object
      let reservationStart = reservation.timeStart.toDate ? reservation.timeStart.toDate() : new Date(reservation.timeStart);
      let reservationEnd = reservation.timeEnd.toDate ? reservation.timeEnd.toDate() : new Date(reservation.timeEnd);
      // console.log("infos reservation concerned:__", reservationStart, reservationEnd);

      // Process the logic only if reservationStart and reservationEnd are valid dates
      if (!isNaN(reservationStart) && !isNaN(reservationEnd)) {
        // récupérer les slots concernés par cette réservation
        
        slots.forEach(slot => {
          // Extract hours and minutes from the slot.time string
          const [hours, minutes] = slot.time.split(':').map(str => parseInt(str, 10));
          // Create a new Date object for the slot time on the same day as the reservation
          let slotDateTime = new Date(reservationStart.getTime());
          slotDateTime.setHours(hours);
          slotDateTime.setMinutes(minutes);
          slotDateTime.setSeconds(0);
          slotDateTime.setMilliseconds(0);

          // on additionne le current slot time avec le temps de réservation --> Impossible de déterminer correctement le duration 
          let endSlotDateTime = new Date(slotDateTime.getTime()); // Duplicate slotDateTime
          const durationInMilliseconds = reservationEnd.getTime() - reservationStart.getTime();
          endSlotDateTime.setTime(slotDateTime.getTime() + slot.duration * 60000); // Add duration in minutes converted to milliseconds

          // delete les slots de la journée déjà passés.
          if (slotDateTime < new Date(new Date().setHours(new Date().getHours() + 1))) {
            let slotToUpdate = filteredSlots.find(s => s.time === slot.time);
            
            slotToUpdate.tables = []
          }
          else if (slot.tables.includes(reservation.tableID)) {
                      // la logique c de checker si le créneauu observer en comptant le temps de réservation qui va avec il faut que sa fin de créneau soit avant le début de la résa et le début du créneau doit être après la fin de résa.
            if ((reservationStart > slotDateTime && reservationStart < endSlotDateTime) || (reservationStart <= slotDateTime && reservationEnd > slotDateTime)) {
              let slotToUpdate = filteredSlots.find(s => s.time === slot.time);

              if (slotToUpdate && Array.isArray(slotToUpdate.tables) && slotToUpdate.tables.length > 0) {
                // Supprimer l'élément de slotToUpdate.tables dont tableId === reservation.tableID
                const indexToRemove = slotToUpdate.tables.findIndex(tableId => tableId === reservation.tableID);

                // Si l'élément existe dans le tableau, le supprimer
                if (indexToRemove !== -1) {
                  slotToUpdate.tables.splice(indexToRemove, 1);
                }
                if (slotToUpdate.tables.length === 0) {
                  slotToUpdate = null;
                }
              }
            }
          }
        });
      }
    }
  });
  console.log("FINAL SLOT__:", filteredSlots);

  return filteredSlots;
}

function formatDateToFrench(date) {
  const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false };
  return date.toLocaleDateString('fr-FR', options).replace('à', 'à').replace(':', 'h');
}



export default Reservation;
