import React, { useContext, useEffect, useState } from 'react';
import './Dropdown.css';
import ReservationContext from './webService/Reservations';

const SelectorWithDropdown = ({ options, containerName, title }) => {
  const { handleSelectSlot, handleSelectCustomers, resetStates, setHoraireSelection, setQuantitySelection  } = useContext(ReservationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [customersSelection, setCustomersSelection] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const getButtonText = () => {
    if (containerName === 'customers') {
      return customersSelection || 'Choisir';
    } else if (containerName === 'slots') {
      return selectedSlot ? selectedSlot.time : 'Choisir';
    }
  };

  const handleSelection = (selection) => {

    if (containerName === "customers") {
      if (selection !== null) {
        console.log("Valid selection", selection);
        setQuantitySelection(true);
        setCustomersSelection(selection);
      } else {
        console.log("Invalid selection: null");
      }
    } else {
        console.log("handleSelection slots", selection, options);
        setHoraireSelection(true);

        setSelectedSlot(selection);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("handleSelectCustomers(customersSelection: ", customersSelection);
    handleSelectCustomers(customersSelection);
  }, [customersSelection]);

  useEffect(() => {
    console.log("useEffect handleSelection slots", selectedSlot);
    handleSelectSlot(selectedSlot); // Appeler la fonction du contexte avec l'horaire sélectionné
  }, [selectedSlot]);

  useEffect(() => {
    if (resetStates === true) {
      console.log("Resetting states due to resetStates being true");

      setSelectedSlot(null);
      setCustomersSelection(null);
      setQuantitySelection(false);
    }
  }, [resetStates]);

  return (
      <div className="calendar-header-section">
          <h2 className="dropdown-title">{title}</h2>
          <div className='dropdown-block'>
            <button className="dropdown-button" onClick={toggleDropdown}>
              {getButtonText()}
            </button>
            <div className={`dropdown-list-container ${isOpen ? 'open' : ''}`}>
              <ul className="dropdown-list">
                {containerName === 'customers' ? (
                  options.map((option, index) => (
                  <li key={index} className="dropdown-list-item" onClick={() => handleSelection(option)}>
                    {option}
                  </li>
                ))
                ) : (
                  options.map((option, index) => (
                    <li
                      key={index}
                      className={`dropdown-list-item ${option.tables.length === 0 ? "non-clickable" : ""}`}
                      onClick={() => option.tables.length !== 0 && handleSelection(option)}
                    >
                      {option.time}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>  
      </div>
    );
};
export default SelectorWithDropdown;

