import React, { useContext, useState, useEffect } from 'react';
import ReservationContext from './webService/Reservations';
import './NameField.css'

const NameField = () => {
    const [name, setName] = useState('');
    const { handleCustomerNameChanges } = useContext(ReservationContext);

    const handleInputChange = (event) => {
        setName(event.target.value);
    };

    useEffect(() => {
        handleCustomerNameChanges(name);
    }, [name]); // Assurez-vous d'inclure name dans le tableau de dépendances

    return (
        <div className="input-container">
            <h2 className="label-field">Nom de la réservation :</h2>
            <input
                type="text"
                id="nameInput"
                name="name"
                className="input-field"
                value={name}
                onChange={handleInputChange}
                placeholder="Entrez votre nom"
            />
        </div>
    );
}

export default NameField;