import React, { Component, useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Style par défaut que vous pourriez vouloir surcharger
import './App.css'
import './Dropdown.css'

import { ReservationContext } from './webService/Reservations';

  class CalendarView extends Component {
    static contextType = ReservationContext;
  
    state = {
        calendarVisible: false,
        date: new Date()
    };

    toggleCalendar = () => {
     this.setState(prevState => ({
        calendarVisible: !prevState.calendarVisible,
     }));
    };
  

  handleOptionClick = (option) => {
    console.log(option); // Handle the option click (e.g., set state, form submission)
    this.toggleDropdown(); // Optionally close the dropdown
  };

  DisplayCalendar = () => {
    const { calendardate } = useContext(ReservationContext);

    return (
        <Calendar
        onChange={this.updateCalendar}
        value={calendardate ? new Date(calendardate) : new Date()}
        minDate={new Date()}
        // Vous pouvez définir ici d'autres props comme maxDate, minDate, tileClassName, etc.
    />
    );
  }

  DisplayPlaceholder = () => {
    const { calendardate } = this.context; // Utilisez this.context pour accéder à la date du contexte

    return (
        <button className="calendar-button" onClick={this.toggleCalendar}>{calendardate ? formatDate(calendardate.toString()) : 'Select date'}</button>
    );
  }

  updateCalendar = newDate => {
    const { handleSelectDate } = this.context;
    
    handleSelectDate(newDate); // Supposons que setDate est une fonction fournie par votre contexte pour mettre à jour la date
    console.log("new date", newDate);
    this.toggleCalendar();
  };

  CalendarHeader = () => {

    return (
      <div className="calendar-header-section">
        <h2 className="calendar-title">Dates disponibles :</h2>
        <this.DisplayPlaceholder/>
      </div>
    );
  };


  render() {
    return (
        <div className="calendar-container">
            <this.CalendarHeader/>
            {this.state.calendarVisible && (
                <div className="calendar">
                    <this.DisplayCalendar/>
                </div>
            )}
        </div>
    
      );
  }
};

function formatDate(dateString) {
    const months = [
      "janvier", "février", "mars", "avril", "mai", "juin",
      "juillet", "août", "septembre", "octobre", "novembre", "décembre"
    ];
  
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  }

export default CalendarView;