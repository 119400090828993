import React, { Component } from "react";
import './App.css'


class SectionHorizontal extends Component {
    render() {
      // Destructure the props for easier access
      const {anotherComponent: AnotherCompopent } = this.props;
      
      return (
        <div className="horizontalsection">
          {AnotherCompopent && <AnotherCompopent />}
        </div>
      );
    }
  }

  export default SectionHorizontal