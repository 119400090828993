import React, { useEffect, useState, useContext } from 'react';
import './phoneNumberInput.css'; // Assurez-vous de créer ce fichier CSS pour le style
import ReservationContext from "./webService/Reservations";

const PhoneNumberInput = () => {
  const { setPhoneNumber } = useContext(ReservationContext);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+33'); // L'indicatif par défaut pour la France

  useEffect(() => {
    // Vérifie si le numéro commence par un '0' et le retire si nécessaire
    const cleanPhone = phone.startsWith('0') ? phone.slice(1) : phone;
    if (cleanPhone.length > 0) {
      const fullPhoneNumber = `${countryCode} ${cleanPhone}`;
      console.log("phone change :", fullPhoneNumber); // Pour vérifier le résultat
      setPhoneNumber(fullPhoneNumber);
    }
  }, [phone, countryCode]);

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const preventPaste = (event) => {
    event.preventDefault();
  };

  const preventCopy = (event) => {
    event.preventDefault();
  };

  const preventCut = (event) => {
    event.preventDefault();
  };

  return (
    <div className="phone-number-container">
      <label className="test" htmlFor="phone-number">Numéro de téléphone :</label>
      <div className="input-group">
        <select 
          className="country-code"
          name="countryCode"
          id="countryCode"
          value={countryCode}
          onChange={handleCountryCodeChange}>
          <option value="+33">FR (+33)</option>
          <option value="+1">US (+1)</option>
          <option value="+44">UK (+44)</option>
          <option value="+32">BE (+32)</option>
          <option value="+262">RUN (+262)</option>
          <option value="+590">GUA (+590)</option>
          <option value="+596">MAR (+596)</option>
          <option value="+49">ALL (+49)</option>
          <option value="+31">PB (+31)</option>
        </select>
        <input
          type="tel"
          id="phone-number"
          name="phoneNumber"
          placeholder="625685734"
          pattern="[0-9]*"
          inputMode="numeric"
          value={phone}
          onChange={handlePhoneChange}
          onPaste={preventPaste}
          onCopy={preventCopy}
          onCut={preventCut}
        />
      </div>
      <p className="warning">En cas de numéro de téléphone erroné votre réservation ne sera pas prise en compte.</p>
    </div>
  );
};

export default PhoneNumberInput;
