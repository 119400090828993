import React from 'react';
import './TablesGrid.css'; // Assurez-vous que le chemin vers votre fichier CSS est correct
import TableSelector from './TableSelector';

const TableGrid = ({ tables }) => {
    return (
        <div className="table-grid-container">
            {tables.length > 0 ? (
            tables.map((table) => (
                <TableSelector key={table.id} table={table} />
            ))
        ) : (
            <div></div> // Message ou autre élément JSX lorsque tables est vide
        )}
        </div>
    );
};

export default TableGrid;

