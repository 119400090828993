import React, { useContext, useState } from 'react';
import './RestauList.css'; // N'oublie pas de créer le fichier CSS correspondant
import './RestauCard.css';
import placeholderIcon from './logo.svg'; // Icône de placeholder, remplace-la par l'importation de tes icônes
import ReservationContext from './webService/Reservations';
import logo from './logo.svg';
import { ActionType } from './ActionType'; // Importation de l'enum

const RestauList = ({ actions }) => {
  const { restauSelected } = useContext(ReservationContext);
  const [selectedId, setSelectedId] = useState(null); // Add state to track the selected action

  const handleClick = (id, onClick) => {
    setSelectedId(id); // Update the selected ID state
    onClick(); // Execute the original onClick function
  };


  const isSelected = (id) => {
   switch (restauSelected) {
      case "L5vHL7z7dcdPVAiiXtMJGIjlLlF2":
        if (id === 1) {
          return true;
        }
        break;
      case "I2tSGJEdBZQRxT6ZXf0r51eFbfl1":
        if (id === 2) {
          return true;
        }
        break;
      case "OO7cdEYfC3gybADPrcHSkiJyTrv2":
        if (id === 3) {
          return true;
        }
        break;
      case "a8UkOXK5VffDK0lvo5kzRsiWdXc2":
        if (id === 4) {
          return true;
        }
        break;
      case "rQVzDhf27NfK62UE2VB6UYMkXZ43":
        if (id === 5) {
          return true;
        }
        break;
      case "P4dVdEAfmddEnj0SMLdxoG2xCn32":
        if (id === 6) {
          return true;
        }
        break;
      default:
        return false;
    }
    
    return false;
  };

  /*const handleClick = (index) => {
    console.log("INDEX CLICK ACTION:", index, actionType, actions, actions[index]);
    switch(actions.title) { 
      case "reservation":
        actions[index].onClick();
        break; // Ajoute un break ici
      case "clickandcollect":
        window.location.href = "https://www.google.com";
        break; // Ajoute un break ici
      default:
        console.warn("Action type inconnu:", actionType);
    }
  };*/

  return(
    <div className="action-list">
    {actions.map((action, index) => (
      <div key={action.id} className={`action-item ${index === 0 && actions.length % 2 !== 0 ? 'full-width' : ''}`}>
        <RestauCard icon={action.icon} title={action.title} isSelected={selectedId === action.id}  onClick={() => handleClick(action.id, action.onClick)} />
      </div>
    ))}
  </div>
  );
};

const RestauCard = ({ icon = placeholderIcon, title, isSelected, onClick }) => {

  return(
      <div className={`action-card ${isSelected ? 'selected' : ''}`} onClick={onClick} role="button" tabIndex={0}>
          <p className="action-card-title">{title}</p>
      </div>
  );
};

export default RestauList;
