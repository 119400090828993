import React, { useContext, useEffect, useRef } from 'react';
import ReservationContext from './webService/Reservations';
import logosista from './logosistaplace.svg';
import './Header.css'; // Assure-toi que le chemin est correct
import textFit from 'textfit';

const Header = () => {
  const adresseRef = useRef(null);
  const { getAddressFromSelection } = useContext(ReservationContext);
    // Fonction pour transformer restauSelected en adresse

    const adresse = getAddressFromSelection();
    
    useEffect(() => {
      if (adresseRef.current) {
          textFit(adresseRef.current, { multiLine: false});
      }
    }, []);
  return (
      <div className="header-container">
          <img className="logosista" src={logosista} alt="Sista Place"/>
          {adresse !== "Adresse inconnue" && 
            (<p className="header-address" ref={adresseRef}>{adresse}</p>)
          }
      </div>
  );
};

export default Header;