import React from 'react';
import StyledButton from './StyledButton';
import './Home.css';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleReservationClick = () => {
    // logiquement cela redirige vers la page de réservation
    navigate('/reservation');
  };

  const handleClickAndCollectClick = () => {
    navigate('/clickandcollect'); // Redirige vers la liste des restaurants pour Click & Collect
    // window.location.href = 'https://url-de-ta-webapp-click-and-collect.com'; // Remplace cette URL par l'URL finale de ta webapp Click & Collect
  };

  return (
    <div className="button-container">
      <StyledButton text="Réservation" onClick={handleReservationClick} />
      <StyledButton text="Click & Collect" onClick={handleClickAndCollectClick} />
    </div>
  );
};

export default HomePage;
