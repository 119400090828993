import React, { Component } from "react";
import './App.css'; // Make sure to create this CSS file and import it

class SelectionnableButton extends Component {
  state = {
    isSelected: false// Example options
  };

  isSelected = () => {
    this.setState(prevState => ({
        isSelected: !prevState.isSelected
    }));
  };

  handleOptionClick = (option) => {
    console.log(option); // Handle the option click (e.g., set state, form submission)
    this.toggleDropdown(); // Optionally close the dropdown
  };

  render() {
    return (
      <div className="selectionnable">
        <button onClick={this.isSelected}>Select an Option</button>
      </div>
    );
  }
}

export default SelectionnableButton;
