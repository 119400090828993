import React from 'react';
import './App.css'; // Assuming your CSS styles are saved in MyForm.css
import SectionBlock from "./sectionblock";

class Fields extends React.Component {
  constructor(props) {
    super(props);
    this.state = { phoneNumber: '', comments: '' };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event) => {
    alert('A form was submitted: ' + this.state.phoneNumber + ' - ' + this.state.comments);
    event.preventDefault();
  }

  render() {
    return (
      <div className="ReservationView">
      <SectionBlock
        title="Phone Number:"
        anotherComponent={
            <label>
                Phone Number:
                <input
                    type="tel"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.handleInputChange}
                    className="phoneNumberField"
                />
             </label>
         }
        />
       <SectionBlock
            title="informations supplémentaires (optionnel) :"
            anotherComponent={
                <label>
                   Remarks/Comments:
                    <textarea
                    name="comments"
                    value={this.state.comments}
                    onChange={this.handleInputChange}
                    className="commentsField"
                     />
             </label>
            }
       />
      </div>
    );
  }
}

export default Fields;
