// Import the individual Firebase services you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCZvbjt9RChKrwhP0xwo4fszkutwz-ahJI",
    authDomain: "miamapp-cc1ca.firebaseapp.com",
    databaseURL: "https://miamapp-cc1ca-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "miamapp-cc1ca",
    storageBucket: "miamapp-cc1ca.appspot.com",
    messagingSenderId: "573895344210",
    appId: "1:573895344210:web:1b21c2ae57c2e4b62cdf8b",
    measurementId: "G-7CZ85Q2PFK",
};

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);

export { firestore };