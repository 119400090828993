import React from 'react';
import './StyledButton.css';

const StyledButton = ({ text, onClick }) => {
  return (
    <button className="styled-button" onClick={onClick}>
      {text}
    </button>
  );
};

export default StyledButton;
