import React, {useRef, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import checkmarkIcon from './checkmark-icon.svg'; // Assurez-vous d'ajouter l'icône correspondante dans votre projet
import logo from './logoSistaJaune.svg'; // Assurez-vous d'ajouter votre logo dans votre projet
import './ConfirmationPage.css'; // Créez et ajoutez les styles correspondants
import textFit from 'textfit';
import poweredlogo from './Powered by MIAM blue.svg';

const ConfirmationPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const headingRef = useRef(null);
  const adresseRef = useRef(null);

  useEffect(() => {
    if (headingRef.current) {
      textFit(headingRef.current, { multiLine: false });
    }
    if (adresseRef.current) {
        textFit(adresseRef.current, { multiLine: false});
    }
  }, []);

  // Remplacez les valeurs par défaut par les données reçues via l'état de navigation, si disponible
  const {
    name = 'Nom inconnu',
    adresse = 'aucune adresse spécifiée',
    numberOfPeople = 0,
    date = 'Date inconnue',
    phone = 'Numéro inconnu',
  } = state || {};

  const handleNewReservationClick = () => {
    navigate('/'); // Remplacez '/reservation' par le chemin réel de votre page de réservation
    window.location.reload(true);
  };

  return (
    <div className="confirmation-page">
        <div className="confirmation-content">
            <div className='corps-de-page'>
              <div className='confirmation-header' >
                <h1 ref={headingRef}>Réservation confirmée</h1>
                {/*<img src={checkmarkIcon} alt="Confirmed" className="confirmation-checkmark" />*/}
              </div>
              <img src={logo} alt="Logo" className="confirmation-logo" />
              <p className="confirmation-address" ref={adresseRef}>{adresse}</p>
              <div className='reservation-section'>
                <p className='underline-style'> Informations de la réservation :</p>
                <div className="reservation-info">
                    <p>Nom : {name}</p>
                    <p>Nombre de personnes : {numberOfPeople}</p>
                    <p>Date : {date}</p>
                    <p>Numéro de téléphone : {phone}</p>
                </div>
              </div>
              <div className='bas-de-page'>
                <p className='underline-style info-bas-de-page '>Votre demande de réservation a été prise en compte.</p>
                <p className='underline-style info-bas-de-page '>Pour modifier votre réservation contactez le restaurant directement.</p>

              </div>
            </div>
           
            <div className='reservation-button-block'>
              <button className='buttoncomeback' onClick={handleNewReservationClick}>Réserver à nouveau</button>
            </div>
        </div>

        <div className="confirmation-footer">
          <img src={poweredlogo} alt="Description de l'image" width="100px" height="auto" />
        </div>
    </div>
  );
};

export default ConfirmationPage;
