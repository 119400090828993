import React, { useEffect, useState, useContext } from 'react';
import './TextAreaWithLabel.css'; // Assurez-vous de créer ce fichier CSS
import ReservationContext from "./webService/Reservations";

const TextAreaWithLabel = () => {
  const { setNotes } = useContext(ReservationContext);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    console.log("input value :", inputValue);
    setNotes(inputValue);
  }, [inputValue]);

  return (
    <div className="textarea-container">
      <label htmlFor="supplementaryInfo">
        Information supplémentaires (optionnel) :
      </label>
      <textarea
        id="supplementaryInfo"
        name="supplementaryInfo"
        placeholder="Par exemple : Je viens en poussette avec mon enfant en bas âge"
        value={inputValue}
        onChange={handleChange}
        rows="4" // Définissez le nombre de lignes que vous souhaitez afficher par défaut
      />
    </div>
  );
};

export default TextAreaWithLabel;
