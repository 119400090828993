import React, { Component } from "react";
import './App.css'


class SectionBlock extends Component {
    render() {
      // Destructure the props for easier access
      const {title, anotherComponent: AnotherCompopent } = this.props;
      
      return (
        <div className="verticalsection">
          {title && <h2>{title}</h2>} {/* This will display the title if provided */}
          {AnotherCompopent && <AnotherCompopent />} {/* This will render the passed component */}
        </div>
      );
    }
  }

  export default SectionBlock